import gsap, { TimelineLite, TweenLite } from 'gsap'
import ScrollMagic from 'scrollmagic'
// import 'debug.addIndicators'

// !TODO: refactor this and every place this code is used
function ScrollParallax(scrollMagicScene, sceneController, timeline) {
  this.scrollMagicScene = scrollMagicScene
  this.sceneController = sceneController
  this.timeline = timeline
  this.options = {}
}

ScrollParallax.prototype.init = function init(
  options,
  timelineVarsStart,
  timelineVarsEnd,
) {
  let _timeline = this.timeline
  this.options = options
  this.destroyed = false

  if (!this.initiated) {
    this.sceneController = new ScrollMagic.Controller()
    this.scrollMagicScene = new ScrollMagic.Scene({
      triggerElement: options.SMtriggerElement,
      duration: options.SMduration,
      offset: options.offset || 0,
      triggerHook: options.triggerHook || 0.5,
    }).addTo(this.sceneController)
    // .addIndicators()
    if (options.SMpin) {
      this.scrollMagicScene.setPin(options.SMpin)
    }
    if (options.SMtriggerHookTop) {
      this.scrollMagicScene.triggerHook(0)
    } else if (options.SMtriggerHook !== undefined) {
      this.scrollMagicScene.triggerHook(options.SMtriggerHook)
    } else {
      this.scrollMagicScene.triggerHook(0.5)
    }
    if (options.SMoffset) {
      this.scrollMagicScene.offset(options.SMoffset)
    }

    _timeline = new TimelineLite({
      paused: true,
    })
    _timeline.fromTo(
      options.GSelement,
      options.GSduration,
      timelineVarsStart,
      timelineVarsEnd,
    )

    this.scrollMagicScene.on('progress', function onProgress(event) {
      gsap.to(_timeline, options.GSduration, {
        progress: event.progress.toFixed(2),
      })
    })

    this.initiated = true
  }
}

ScrollParallax.prototype.destroyController = function destroyController() {
  this.initiated = false
  if (!this.destroyed) {
    TweenLite.set(this.options.GSelement, { clearProps: 'all' })
    this.sceneController.destroy(true)
    this.sceneController = null
    this.destroyed = true
  }
}

export default ScrollParallax
